export enum DisplayLocation {
    "LOGIN" = "LOGIN",
    "ABLESEDIENST" = "ABLESEDIENST",
    "ANALYSE" = "ANALYSE",
    "ARCHIV" = "ARCHIV",
    "DASHBOARD" = "DASHBOARD",
    "LIEGENSCHAFT-DASHBOARD" = "LIEGENSCHAFT-DASHBOARD",
    "DATENTAUSCH" = "DATENTAUSCH",
    "HEIZKOERPERBEWERTUNG" = "HEIZKOERPERBEWERTUNG",
    "KONTAKT" = "KONTAKT",
    "KOSTENERFASSUNG" = "KOSTENERFASSUNG",
    "POSTBOX" = "POSTBOX",
    "RAUCHMELDER" = "RAUCHMELDER",
    "UVI" = "UVI",
    "ZWISCHENABLESUNG" = "ZWISCHENABLESUNG",
}