<script setup lang="ts">
import { ref, Ref, onMounted, computed, watch, ComputedRef } from 'vue';
import { loadMessagesResponseItem, updateViewerLocationCollapseState, dismissViewerNewsItem } from '@/client/backend.client';
import { DisplayLocation } from '@/client/dto/DisplayLocation';
import { LayoutVariant } from '@/client/dto/LayoutVariant';
import { ViewerSearchQueryResult, ViewerSearchQueryResultItem } from '@/client/dto/ViewerSearchQueryResult';

import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
import ChevronUpIcon from "@/assets/chevron_up.svg";
import ChevronDownIcon from "@/assets/chevron_down.svg";
import { useElementSize } from '@vueuse/core'

export interface Props {
    displayLocation: DisplayLocation,
    backendUrl?: string,
    queryDate?: string
}

const carouselRef = ref<InstanceType<typeof Carousel> | null>(null);
const someElInTemplate = ref<HTMLElement>();

const props = withDefaults(defineProps<Props>(), {
    queryDate: () => (new Date()).toISOString(),
    backendUrl: () => window.location.origin
});

const layoutVariant: ComputedRef<LayoutVariant> = computed(() => {
    if (props.displayLocation == DisplayLocation.LOGIN)
        return LayoutVariant.LOGIN;
    else
        return LayoutVariant.DEFAULT;
})

const displayData: Ref<ViewerSearchQueryResult> = ref(null);
const { width, height } = useElementSize(document.documentElement);

onMounted(async () => {
    displayData.value = await loadMessagesResponseItem(props.backendUrl, {
        queryDate: new Date(props.queryDate),
        displayLocation: props.displayLocation,
    });
    collapsed.value = displayData.value?.displayLocationCollapsed;
    // Animation wird erst etwas später aktiviert um das initiale Geflacker zu verhindern
    setTimeout(() => loaded.value = true, 500);
})

watch(displayData, (newValue) => {
    if (newValue?.items?.length == 0) {
        getHostNode()?.setAttribute("is-empty", "");
    } else {
        getHostNode()?.removeAttribute("is-empty");
    }
});

const getHostNode = (): HTMLElement => {
    // Das ist ein wenig hacky, aber vue bietet hier einfach keinen einfacheren Weg.
    return (someElInTemplate.value?.getRootNode() as ShadowRoot)?.host as HTMLElement;
}

const wrapAround = computed(() => {
    return displayData.value?.items?.length > 1;
})

const collapsed = ref(true);
const loaded = ref(false);

const onToggleCollapse = async () => {
    collapsed.value = !collapsed.value;
    await updateViewerLocationCollapseState(props.backendUrl, {
        displayLocation: props.displayLocation,
        collapsed: collapsed.value
    });
}

const onDismissItem = async (item: ViewerSearchQueryResultItem) => {

    // dismiss in Backend
    await dismissViewerNewsItem(props.backendUrl, {
        newsDocumentId: item.id,
        displayLocation: props.displayLocation
    });

    // dismiss in UI without reloading
    displayData.value = {
        displayLocationCollapsed: displayData.value.displayLocationCollapsed,
        items: displayData.value.items?.filter(x => x != item)
    };

}
const onCarouselInit = (e) => {
    switchHeadNewsTitle(displayData.value?.items?.[0]);
}
const onCarouselSlideStart = (e) => {
    let arrayIndex = e?.slidingToIndex % e?.slidesCount;
    switchHeadNewsTitle(displayData.value?.items?.[arrayIndex]);
}

const switchHeadNewsTitleText = ref();
const switchHeadNewsTitleClasses = ref();
const switchHeadNewsTitle = async (nextSlide: ViewerSearchQueryResultItem) => {
    if (switchHeadNewsTitleClasses.value) {
        switchHeadNewsTitleClasses.value = "animation-fadeout-1000";
        await new Promise(r => setTimeout(r, 1000));
    }
    if (nextSlide) {
        switchHeadNewsTitleText.value = nextSlide?.ueberschrift;
        switchHeadNewsTitleClasses.value = "animation-fadein-1000";
        await new Promise(r => setTimeout(r, 1000));
    }
    switchHeadNewsTitleClasses.value = "";
}

const autoPlayDisabled = ref(false);
const autoplay = computed(() => {
    return autoPlayDisabled.value ? 0 : 10000;
});

const activeSlides = computed(() => {
    // Wenn zugeklappt, dann sollen keine Items aktiv sein
    if (collapsed.value)
        return [];

    // Ansonsten gelten alle angezeigten Items als aktiv
    // Siehe https://ismail9k.github.io/vue3-carousel/api/data.html
    let carousselData = (carouselRef.value as any)?.data;
    let itemsToShow = Math.ceil(carousselData?.config?.itemsToShow ?? 1);
    let currentSlide = carousselData?.currentSlide?.value ?? 0;
    let maxItems = (carousselData?.maxSlide?.value ?? 0) + 1;
    return Array.from(
        { length: itemsToShow },
        (value, index) => ((currentSlide + index) % maxItems)
    );
});

</script>

<template>
    <div ref="someElInTemplate">
        <template v-if="displayData?.items?.length > 0">
            <template v-if="layoutVariant == LayoutVariant.DEFAULT">
                <div class="news-component-viewer" :class="{ 'collapsed': collapsed, 'enable-animation': loaded }">
                    <div class="news-component-viewer-header" @click.prevent="onToggleCollapse">
                        <div class="news-component-viewer-header-toggle">
                            <ChevronDownIcon v-if="collapsed" />
                            <ChevronUpIcon v-else />
                        </div>
                        <h3 class="news-component-viewer-header-text">
                            News
                        </h3>
                        <h4 class="news-component-viewer-header-news-title" :class="switchHeadNewsTitleClasses"
                            :title="switchHeadNewsTitleText">
                            {{ switchHeadNewsTitleText }}
                        </h4>
                    </div>
                    <div class="news-component-viewer-body" v-if="!collapsed">
                        <Carousel :autoplay :pauseAutoplayOnHover="true" :breakpoints="{
            0: {
                itemsToShow: 1
            },
            600: {
                itemsToShow: 1
            },
            1280: {
                itemsToShow: 1
            }

        }" :wrapAround :i18n="{
            iconArrowRight: 'weiter',
            iconArrowLeft: 'zurück',
            itemXofY: '{currentSlide} von {slidesCount}',
        }" @slideStart="onCarouselSlideStart" @init="onCarouselInit"
                            @click="autoPlayDisabled = true" ref="carouselRef">
                            <template #slides="{ slidesCount, currentSlide }">
                                <Slide v-for="slide in displayData?.items?.length" :key="slide">
                                    <news-component-item :data="displayData?.items?.[slide - 1]" :layoutVariant
                                        :backendUrl :isActive="activeSlides?.includes(slide - 1)"
                                        @itemDismissed="onDismissItem(displayData?.items?.[slide - 1])" />
                                </Slide>
                            </template>
                            <template #addons="{ slidesCount, currentSlide }">
                                <Navigation v-if="wrapAround" />
                                <Pagination v-if="wrapAround" />
                            </template>
                        </Carousel>
                    </div>
                </div>
            </template>
            <template v-else-if="layoutVariant == LayoutVariant.LOGIN">
                <news-component-item :data="displayData?.items?.[0]" :layoutVariant :backendUrl />
            </template>
        </template>
    </div>
</template>

<style>
/* stylelint-disable css:S4666 */
/* stylelint-disable css:S4666 */

@import '/theme/brunata.variables.css';
@import 'vue3-carousel/dist/carousel.css';

:host {
    --vc-nav-background: var(--bmePrimary);
    --vc-nav-color: var(--bmeWhite);
    --vc-nav-color-hover: var(--bmeWhite);
    --vc-nav-border-radius: 50%;
    --vc-nav-width: 38px;
    --vc-nav-height: 38px;
    --vc-pgn-background-color: var(--bmeOrange);
    --vc-pgn-active-color: var(--bmeBlue);
}

:host {
    display: block;
}

:host([hidden]) {
    display: none;
}

.news-component-viewer {

    display: grid;
    grid-template-rows: auto 280px;
    gap: 1.5rem;

    font-family: var(--bmeFontFamily);

    .news-component-viewer-header {
        display: grid;
        gap: 1rem;
        justify-content: start;
        align-items: center;
        grid-auto-flow: column;
        padding: 0 2rem;
        margin: 0;
        height: 80px;
        background-color: var(--bmePrimaryLight300);
        border-radius: 1rem;
        cursor: pointer;

        .news-component-viewer-header-text {
            margin: 0;
            color: var(--bmeDarkGrey800);
            font-size: var(--bmeH3FontSize);
            font-weight: var(--bmeH3FontWeight);
            line-height: var(--bmeH3LineHeight);
        }

        .news-component-viewer-header-news-title {
            margin: 0;
            color: var(--bmeDarkGrey800);
            font-size: var(--bmeH4FontSize);
            font-weight: var(--bmeH4FontWeight);
            line-height: var(--bmeH4LineHeight);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 100%;
        }

        .news-component-viewer-header-toggle {
            color: var(--bmePrimary);
        }
    }

    .news-component-viewer-body {
        height: 100%;

        .carousel {
            height: 100%;
            display: flex;
            flex-direction: column;

            .carousel__viewport {
                height: 100%;

                .carousel__track {
                    height: 100%;
                    margin-block: 0;

                    .carousel__slide {
                        padding: 0 4rem;
                        box-sizing: border-box;

                        * {
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
            }

            .carousel__pagination {
                padding-inline: 0;
                gap: 1rem;
                margin-top: 1.5rem;

                .carousel__pagination-item {
                    .carousel__pagination-button {
                        width: var(--bmeNotesFontSize);
                        height: var(--bmeNotesFontSize);
                        border-radius: 100%;
                        border: 2px solid var(--bmeBlue);

                        &.carousel__pagination-button--active {
                            background-color: var(--bmeBlue);
                        }

                        &::after {
                            display: none;
                        }
                    }
                }
            }

            .carousel__prev,
            .carousel__next {
                font-size: var(--bmeBody2FontSize);
                top: calc((100% - 2rem) / 2);

                &:hover {
                    background-color: var(--bmePrimaryHover);
                }
            }
        }
    }
}

@media screen and (max-width: 1280px) {

    /* TODO Confirm Breakpoint */
    .news-component-viewer {
        grid-template-rows: auto 1fr;

        .news-component-viewer-header {
            padding: 0 1rem;
        }

        .news-component-viewer-body {
            .carousel {
                .carousel__viewport {
                    .carousel__track {
                        .carousel__slide {
                            padding: 0 1rem;
                        }
                    }
                }

                /*.carousel__prev, .carousel__next {
                        display: none;
                    }*/
            }
        }
    }
}

/* Auf- und Zuklapp Animation */
.news-component-viewer {
    &.collapsed {
        grid-template-rows: auto 0px;
        gap: 0;

        .news-component-viewer-body {
            opacity: 0;
        }
    }

    &.enable-animation {
        transition: grid-template-rows 0.5s ease-in-out;

        .news-component-viewer-body {
            transition: opacity 0.5s ease-in-out;
        }
    }
}

/* Header News Transition Animation */
.news-component-viewer {
    .animation-fadeout-1000 {
        animation: news-component-viewer-fadeout 1.00s linear;
    }

    .animation-fadein-1000 {
        animation: news-component-viewer-fadein 1.00s linear;
    }
}

@keyframes news-component-viewer-fadeout {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes news-component-viewer-fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>