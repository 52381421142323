import { ViewerSearchQuery } from "./dto/ViewerSearchQuery";
import { ViewerSearchQueryResult } from "./dto/ViewerSearchQueryResult";
import { UpdateViewerLocationCollapseStateCommand } from "./dto/UpdateViewerLocationCollapseStateCommand";
import { DismissViewerNewsItemCommand } from "./dto/DismissViewerNewsItemCommand";

export async function loadMessagesResponseItem(baseUrl: string, query: ViewerSearchQuery) : Promise<ViewerSearchQueryResult> {
    let url = baseUrl + "api/viewer/search";
    let body = JSON.stringify(query, null, 2);
    let headers = { 
        "Accept": "application/json",
        "Content-Type": "application/json" 
    }
    let response = await fetch(url, { 
        method: "POST", 
        headers, 
        body
    });
    try {
        const result = await response.json() as ViewerSearchQueryResult;
        return result;
    } catch {
        return { displayLocationCollapsed: false, items: [] } as ViewerSearchQueryResult;
    }
}

export async function updateViewerLocationCollapseState(baseUrl: string, cmd: UpdateViewerLocationCollapseStateCommand) : Promise<void> {
    let url = baseUrl + "api/viewer/updateViewerLocationCollapseState";
    let body = JSON.stringify(cmd, null, 2);
    let headers = { 
        "Accept": "application/json",
        "Content-Type": "application/json" 
    }
    await fetch(url, { 
        method: "POST", 
        headers, 
        body
    });    
}

export async function dismissViewerNewsItem(baseUrl: string, cmd: DismissViewerNewsItemCommand) : Promise<void> {
    let url = baseUrl + "api/viewer/dismissViewerNewsItem";
    let body = JSON.stringify(cmd, null, 2);
    let headers = { 
        "Accept": "application/json",
        "Content-Type": "application/json" 
    }
    await fetch(url, { 
        method: "POST", 
        headers, 
        body
    });    
}